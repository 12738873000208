@media screen and (max-width: 768px) {
  .coin-section .left {
    display: none;
  }
  .coin-section .mid {
    display: none;
  }
  .coin-section .right {
    display: none;
  }

  .right-border:after {
    display: none;
  }
  .slider {
    height: 40%;
  }
  .slider .slides-holder__item {
    width: 100px !important;
    height: 100px !important;
  }
}
