html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style: none;
  text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
/*ol, ul {*/
/*    list-style: none;*/
/*}*/
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.slider {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  height: 75%;
  z-index: 1;
}
.circular-slider {
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.circular-slider .wrapper {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 20px 0px 20px;
  overflow: hidden;
}
.circular-slider .wrapper .controls__left,
.circular-slider .wrapper .controls__right,
.circular-slider .wrapper .controls__autoplay {
  position: absolute;
  z-index: 101;
  -webkit-transition: 0.6s all;
  -o-transition: 0.6s all;
  transition: 0.6s all;
}
.circular-slider .wrapper .controls__left:hover .icon-wrapper,
.circular-slider .wrapper .controls__right:hover .icon-wrapper,
.circular-slider .wrapper .controls__autoplay:hover .icon-wrapper {
  font-size: 1.7em;
  opacity: 1;
}
.circular-slider .wrapper .controls__left .icon-wrapper,
.circular-slider .wrapper .controls__right .icon-wrapper,
.circular-slider .wrapper .controls__autoplay .icon-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #8eb8e5;
  font-size: 1.5em;
  opacity: 0.7;
}
.circular-slider .wrapper .controls__left,
.circular-slider .wrapper .controls__right {
  top: 50%;
}
.circular-slider .wrapper .controls__left {
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.circular-slider .wrapper .controls__left:hover {
  left: 0;
}
.circular-slider .wrapper .controls__right {
  right: 0;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}
.circular-slider .wrapper .controls__autoplay {
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}
.circular-slider .wrapper .controls__autoplay_running .pause {
  display: block;
}
.circular-slider .wrapper .controls__autoplay_running .run {
  display: none;
}
.circular-slider .wrapper .controls__autoplay_paused .pause {
  display: none;
}
.circular-slider .wrapper .controls__autoplay_paused .run {
  display: block;
}
.circular-slider .wrapper .slides-holder {
  border-radius: 50%;
  border: 2px solid #ffffff00;
  transform-origin: center;
  -webkit-box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  z-index: 100;
}
.circular-slider .wrapper .slides-holder__item {
  border-radius: 50%;
  border: 2px solid #7c99b4;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  background-color: #222;
  -webkit-transition: 0.3s linear all;
  -o-transition: 0.3s linear all;
  transition: 0.3s linear all;
  -webkit-filter: brightness(70%);
  filter: brightness(70%);
}
.circular-slider .wrapper .slides-holder__item img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: rotate(180deg);
}
.circular-slider .wrapper .slides-holder__item_active {
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
}
.circular-slider .wrapper .descriptions {
  position: absolute;
  bottom: 0%;
  z-index: 0;
}
.circular-slider .wrapper .descriptions__item {
  width: 100%;
  height: 0%;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: opacity 0s 0s linear;
  -o-transition: opacity 0s 0s linear;
  transition: opacity 0s 0s linear;
}
.circular-slider .wrapper .descriptions__item_visible {
  height: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.6s 0s linear;
  -o-transition: opacity 0.6s 0s linear;
  transition: opacity 0.6s 0s linear;
}
.circular-slider .wrapper .descriptions__item h1,
.circular-slider .wrapper .descriptions__item .description {
  font-family: Helvetica, sans-serif;
  color: white;
  text-align: center;
}
.circular-slider .wrapper .descriptions__item h1 {
  font-size: 200%;
  padding-top: 5px;
}
.circular-slider .wrapper .descriptions__item .description {
  font-size: 100%;
  margin-top: 5px;
  padding: 0% 10%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow-y: hidden;
}

/* body {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
} */
h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 72px;
  color: #124265;
}

h2 {
  color: var(--main-nav-footer);
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 35px;
}

@media (max-width: 992px) {
  h1 {
    font-size: 28px;
    line-height: 36px;
  }
  h2 {
    font-size: 18px;
    line-height: 24px;
  }
}
