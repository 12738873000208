:root {
  --main-color: #d9205973;
  --main-bg-color: brown;
  --main-layout-color: #0112fe38;
  --main-active-border: #9e1daf;
  --main-layout-offer: #061c3173;
  --main-button: #d92059;
  --main-nav-footer: #081828;
  --main-nav-insider: rgba(8, 24, 40, 0.78);
}

body {
  background: #f0f2f5;
}
#root {
  background: #fff;
  height: 100vh;
  /* display: flex; */
  width: 100%;
  /* flex-direction: row-reverse; */
}

.main {
  width: calc(100% - 260px);
  height: 100vh;
}

/* signup styles */
.signup-page,
.signin-page,
.verify-keys-page {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  font-size: 24px;
  font-weight: 500;
  padding: 15px 25px;
}

.signup-container,
.signin-container,
.verify-page-container {
  min-height: 300px;
  width: 60%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px 0;
}

.signup-content,
.signin-content,
.verify-page-content {
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  height: 310px;
}

.signup-content > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.signin-content > div,
.verify-page-content > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  justify-content: space-between;
}

.signup-input-container,
.signin-input-container,
.verify-page-input-container {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.signup-label,
.change-password-input-container > label,
.trade-input-container > label {
  display: block;
  padding: 0.375rem 0rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-size: 23px;
  color: #212529;
  background-color: #fff;
}

.signup-input,
.change-password-input-container > input,
.trade-input-container > input {
  height: 52px;
  line-height: 52px;
  border: 1px solid #e6e2f5;
  font-size: 23px;
  padding: 0 20px;
  color: #333;
  font-weight: 400;
  border-radius: 5px;
}

.signup-error {
  min-height: 15px;
  font-size: 11px;
  color: crimson;
  margin: 5px 0;
  text-align: center;
  font-weight: 500;
}

.signup-success {
  min-height: 15px;
  font-size: 11px;
  color: green;
  margin: 5px 0;
  text-align: center;
  font-weight: 500;
}

.signin-button {
  background: #3795e8;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  opacity: 0.8;
  transition: 0.5s ease;
  width: 45%;
  margin: auto;
}

.signup-button {
  background: #3795e8;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  opacity: 0.8;
  transition: 0.5s ease;
}

.signup-button:hover,
.signin-button:hover {
  opacity: 1;
  cursor: pointer;
}

.PhoneInput {
  display: flex !important;
  outline: none;
}

.PhoneInputInput {
  border: none;
}

/* logo */

img.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 14rem;
}

.userinfo {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  border-radius: 12px;
  background-color: rgba(145, 158, 171, 0.12);
}

/* sidenav bar styles */

.sidenav {
  height: 100%;
  width: 260px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--main-nav-footer);
  overflow-x: hidden;
  padding-top: 20px;
}

.sidenav > div:nth-child(1) {
  display: flex;
  justify-content: center;
}

.right-section {
  float: right;
}

.section-header {
  width: 100%;
}

.sidenav > div:nth-child(2) {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 22px;
}

.sidenav > div > h2 {
  margin-top: 0;
  font-size: 20px;
  color: #ccc;
  text-align: center;
}

.sidenav a {
  outline: 0px;
  border: 0px;
  margin-top: 15px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  text-align: left;
  padding: 8px 20px 8px 40px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: "Public Sans", sans-serif;
  height: 48px;
  position: relative;
  text-transform: capitalize;
  color: #ffffff;
  font-weight: 600;
  /*border-top: 1px solid var(--main-button);*/
}

/*.sidenav a:last-child {*/
/*  border-bottom: 1px solid var(--main-button);*/
/*}*/

.sidenav a:hover {
  color: #c3c3c3;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  background-color: var(--main-button);
}

.sidenav a.active {
  outline: 0px;
  border: 0px;
  margin-top: 15px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  display: flex;
  flex-grow: 1;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  text-align: left;
  padding: 8px 20px 8px 40px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: "Public Sans", sans-serif;
  height: 48px;
  position: relative;
  text-transform: capitalize;
  color: #ffffff;
  font-weight: 600;
  background-color: var(--main-button);
}

/* user dashboard page styles */

.user-dashboard-page-container,
.change-password-page-container,
.update-email-page-container,
.trade-page-container {
  padding: 15px;
}

/*.user-dashboard-page-container > div:nth-child(1) {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*}*/

.user-dashboard-page-content,
.change-password-page-content,
.update-email-page-content {
  height: calc(100vh - 72px);
  overflow: auto;
}

.user-dashboard-page-content > div {
  height: 50%;
  display: flex;
}

.admin-dashboard-page-content > div {
  height: 100%;
  display: flex;
}

.user-dashboard-account-details-section {
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  width: 40%;
  overflow: auto;
}

.user-dashboard-trade-details-section {
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  /* margin-left: 15px; */
  overflow: auto;
}
.change-password-section,
.admin-dashboard-trade-details-section {
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  /* margin-left: 15px; */
  overflow: auto;
}

.user-dashboard-balance-history-section {
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  margin-left: 15px;
  overflow: auto;
}

.user-dashboard-trade-details-section > div,
.admin-dashboard-trade-details-section > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reload-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 5px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 0.875rem;
  text-transform: capitalize;
  font-family: "Public Sans", sans-serif;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: var(--main-button);
}
.refresh-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 5px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 0.875rem;
  text-transform: capitalize;
  font-family: "Public Sans", sans-serif;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: var(--main-nav-footer);
  background-color: var(--main-layout-color);
}

.download-csv-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 5px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 0.875rem;
  text-transform: capitalize;
  font-family: "Public Sans", sans-serif;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #267ec0;
}

.reload-button:hover,
.refresh-button:hover,
.disconnect-button:hover,
.cancel-order-button:hover,
.download-csv-button:hover {
  opacity: 1;
  cursor: pointer;
}

.disconnect-button {
  background: crimson;
  padding: 7px 15px;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  opacity: 0.8;
  transition: 0.5s ease;
  margin: 0 5px;
  height: fit-content;
}

.cancel-order-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 5px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 0.875rem;
  text-transform: capitalize;
  font-family: "Public Sans", sans-serif;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: var(--main-bg-color);
}

.cancel-order-disabled-button {
  border: none;
  border-radius: 5px;
  opacity: 0.8;
  transition: 0.5s ease;
  margin: 0 5px;
  height: fit-content;
  pointer-events: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  color: rgba(145, 158, 171, 0.8);
  box-shadow: none;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 0.875rem;
  text-transform: capitalize;
  font-family: "Public Sans", sans-serif;
  min-width: 64px;
  padding: 6px 16px;
  color: #fff;
  background-color: rgba(145, 158, 171, 0.24);
}

/* .user-dashboard-line-chart-section,
.user-dashboard-table-section {
  height: 50%;
} */

/* change password page styles  */

.change-password-page-content > div {
  padding: 25px 15px;
  background: #fff;
  width: 50%;
  border-radius: 10px;
}

.change-password-input-container {
  display: flex;
  flex-direction: column;
}

.change-password-button {
  outline: 0px;
  border: 0px;
  margin: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  text-decoration: none;
  font-weight: 700;
  width: 100%;
  font-size: 0.875rem;
  min-width: 64px;
  padding: 15px;
  border-radius: 8px;
  color: #fff;
  background: #3795e8;
}

/* update email styles  */
.change-email-content {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.change-email-content > p {
  margin: 0;
  font-size: 14px;
}

.verify-otp-content,
.enter-email-content {
  display: flex;
  align-items: center;
}

.verify-otp-content > p,
.enter-email-content > p {
  font-size: 14px;
  margin: 0;
}

.verify-otp-content > input,
.enter-email-content > input {
  font-size: 14px;
  border-radius: 5px;
  background: #e3e3e3;
  border: none;
  padding: 5px 10px;
  color: #000;
  outline: none;
  margin: 0 15px;
}

.email-page-button {
  background: #3795e8;
  padding: 7px;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  opacity: 0.8;
  transition: 0.5s ease;
  width: fit-content;
}

.email-page-button:hover {
  opacity: 1;
  cursor: pointer;
}

.trade-input-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-right: 15px;
}

.trade-page-content {
  margin: 25px;
  border: 1px solid #888;
  padding: 15px 10px;
  border-radius: 10px;
}

.trade-page-content > h3 {
  font-size: 14px;
  font-weight: 500;
}

.trade-page-content > div {
  display: flex;
  justify-content: center;
}

.buy-trade-button {
  background: green;
  color: #fff;
  border: 1px solid green;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.5s;
}

.sell-trade-button {
  background: crimson;
  color: #fff;
  border: 1px solid crimson;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.5s;
}

.buy-trade-button:hover,
.sell-trade-button:hover {
  opacity: 1;
}

.page-heading {
  margin-bottom: 15px;
  margin-left: 15px;
}

.page-heading h2 {
  font-size: 30px;
  font-weight: 500;
  padding: 5px 10px;
  margin-bottom: 0px;
}

.page-heading p {
  margin: 0;
  padding: 5px 10px;
  font-size: 0.875rem;
  font-weight: 400;
  color: rgb(99, 115, 129);
}

.section-heading {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 0px;
}

.loader-state {
  min-height: 100px;
  font-size: 12px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

/* table styles */
table {
  border-collapse: collapse;
  width: 100%;
}

th:first-of-type {
  padding-left: 24px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: rgb(255 255 255) 8px 0px 0px inset;
}

th:last-of-type {
  padding-right: 24px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: rgb(255 255 255) -8px 0px 0px inset;
}
th {
  line-height: 1.5rem;
  font-size: 0.875rem;
  font-family: "Public Sans", sans-serif;
  font-weight: 600;
  box-shadow: none;
  user-select: none;

  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  padding: 16px;
  border-bottom: none;
  color: rgb(99, 115, 129);
  background-color: rgb(244, 246, 248);
}

td {
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  display: table-cell;
  user-select: none;
  vertical-align: inherit;
  text-align: left;
  padding: 16px;
  color: rgb(33, 43, 54);
  border-bottom: none;
}

td {
  font-size: 12px;
}
