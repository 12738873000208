footer {
  width: 100%;
}
.site-footer {
  color: #fff;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.site-footer a {
  color: #fff;
  text-decoration: none;
}
.site-footer a:hover,
.site-footer a.active {
  color: var(--main-active-border);
}
.site-footer p .fa {
  padding: 0 0.125rem;
}
.site-footer ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.site-footer ul li {
  padding: 0.09rem 0;
}
.social-icons li {
  display: inline-block;
  margin-right: 17px;
}

.site-footer.footer-map {
  background-color: var(--main-nav-footer);
  -webkit-background-size: contain;
  background-size: contain;
  background-position: 50% -200px;
}
.footer-top {
  background-color: var(--main-nav-footer);
  padding: 6rem 0;
}
.footer-top h5 {
  line-height: 37px;
  color: #fff;
  margin-bottom: 2rem;
}
.footer-top .social-icons {
  margin-top: 2rem;
}
.footer-bottom {
  position: relative;
  background-color: #072b3e;
  padding: 1rem 0;
  font-size: 13px;
}
.footer-bottom p {
  margin-bottom: 0;
  line-height: 37px;
}
.scroll-top {
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: #723fe4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #fff !important;
  position: fixed;
  bottom: 100px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 5px;
}
.scroll-top:hover {
  color: #fff;
  background-color: #00283b;
}

.inner-content {
  border: 2px solid rgba(238, 238, 238, 0.171);
  padding: 30px;
  margin-top: 25px;
  border-radius: 8px;
}
.title h3 {
  color: #d2d6dc;
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: capitalize;
}
.title p {
  font-size: 15px;
  color: #d2d6dc;
}
