@import url("footer.css");

:root {
  --main-color: #d9205973;
  --main-bg-color: brown;
  --main-layout-color: #050c6585;
  --main-active-border: #9e1daf;
  --main-layout-offer: #061c3173;
  --main-button: #d92059;
  --main-nav-footer: #081828;
  --main-nav-insider: rgba(8, 24, 40, 0.78);
}

.color-main {
  color: var(--main-active-border);
}

body {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 300;
  line-height: 1.625rem;
  background: #fff !important;
}

/* #root {
  display: block !important;
  height: 100% !important;
  background: #fff !important;
} */

.parent-main > .main {
  background: url("../image/background.jpg") center no-repeat;
  width: 100% !important;
  height: 700px;
  background-size: cover;
}

.main-inside {
  background: url("../image/background.jpg") center no-repeat;
  width: 100%;
  height: 400px;
  background-size: cover;
}

.layout-ground {
  background-color: var(--main-layout-color);
  width: 100%;
  height: 700px;
  position: relative;
}

.layout-inside {
  background-color: var(--main-nav-insider);
  width: 100%;
  height: 400px;
  position: relative;
}

.layout-ground::after {
  content: "";
  background: url("../image/pt1.png") bottom no-repeat;
  position: absolute;
  bottom: 0;
  height: 307px;
  left: 0;
  right: 0;
  width: 100%;
}

.navbar {
  background: transparent !important;
}
.navbar a {
  color: white !important;
}

.navbar-brand > img {
  width: 16rem;
}
a.nav-link.active span {
  border-bottom: 3px solid var(--main-active-border);
  padding-bottom: 5px;
}
a.nav-link span:hover {
  border-bottom: 3px solid var(--main-active-border);
  padding-bottom: 5px;
}

li.nav-item {
  padding-right: 10px;
  padding-left: 10px;
}
.blog-img {
  width: 100%;
  height: 550px;
  max-width: 650px;
}

.coin-section {
  height: 700px;
  position: relative;
  width: 100%;
}
.coin-section .left {
  position: absolute;
  bottom: 126px;
  z-index: 5;
  right: 80%;
}
.coin-section .mid {
  position: absolute;
  bottom: 0px;
  z-index: 5;
  left: 40%;
}
.coin-section .right {
  position: absolute;
  bottom: 126px;
  z-index: 5;
  left: 80%;
  text-align: right;
}
/*.coin-section .col-lg-4{ transition: all 0.5s ease-in-out; }*/
.coin-section .col-lg-4:hover {
  transform: scale(1);
}

.section-offers {
  background: url("../image/offer.jpg") center no-repeat;
  width: 100%;
  min-height: 400px;
  background-size: cover;
}

.section-products {
  background: url("../image/product.jpg") center no-repeat;
  width: 100%;
  min-height: 400px;
  background-size: cover;
}
.section-coins {
  background: #fff;
}
.section-coins,
.section-posts,
.section-info,
.section-counter,
.section-about,
.section-map,
.section-pricing,
.section-cta,
.section-form,
.section-testimonials {
  margin-top: 50px;
  width: 100%;
  margin-bottom: 50px;
}
.section-posts img {
  height: 468px;
  width: 100%;
}

.layout-offers {
  background-color: var(--main-layout-offer);
  width: 100%;
  min-height: 400px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.view {
  position: relative;
  overflow: hidden;
  cursor: default;
}
.overlay .mask {
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.overlay .mask:hover {
  opacity: 1;
}
.view .mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-attachment: fixed;
}
.blue-gradient-rgba {
  background: var(--main-layout-offer);
  width: 300px;
  height: 300px;
}
.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.btn-main {
  background: var(--main-button);
}
.right-border {
  position: relative;
}

.right-border:after {
  content: "";
  border-right: 3px solid #831a96;
  position: absolute;
  right: 0;
  top: 35%;
  width: 1px;
  height: 60px;
}

.fixed-top.scrolled {
  background-color: #072b3e !important;
  transition: background-color 200ms linear;
}
.icon {
  width: 100%;
  text-align: center;
}
.icon img {
  cursor: pointer;
  overflow: hidden;
  border-radius: 50%;
  background-color: #072b3e;
  padding: 10px;
  text-align: center;
}
.icon img {
  width: 185px;
  height: 185px;
}

.box {
  position: relative;
}

.box-body {
  position: absolute;
  top: 30%;
  left: 0;
  background: #072b3e;
  padding: 25px;
}
.blog {
  position: absolute;
  /*top: 70%;*/
  top: calc(100% - 95px);
  left: 0;
  background: #072b3e;
  padding: 25px;
  bottom: 0;
}

.box-body h4 {
  font-size: 30px;
  color: var(--main-active-border);
}

.box-body p {
  color: white;
  font-size: 18px;
  line-height: 1.6;
}

.main-title h2 span {
  font-size: 30px;
  color: var(--main-active-border);
  position: relative;
  border-left: 4px solid var(--main-active-border);
  width: 100%;
  padding-left: 8px;
  font-weight: 600;
}

.main-title p span {
  font-size: 18px;
  color: var(--main-active-border);
  padding-left: 17px;
  line-height: 17px;
}

.main-title {
  margin-bottom: 17px;
}

.msg {
  display: block;
}

.modal-body input:not([type="checkbox"]):not([type="radio"]),
.modal-body select,
.modal-body button {
  height: 52px;
  line-height: 52px;
  width: 100%;
  border: 1px solid #e6e2f5;
  padding: 0 20px;
  color: #333;
  font-weight: 400;
  border-radius: 5px;
}

.social-auth {
  background: var(--main-nav-footer);
  padding: 20px;
  color: #fff;
  cursor: pointer;
  text-align: center;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.section-testimonials .testimonial-item {
  box-sizing: content-box;
  text-align: center;
  min-height: 320px;
}

.section-testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  margin: 0 auto;
}

.section-testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.section-testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.section-testimonials .testimonial-item .quote-icon-left,
.section-testimonials .testimonial-item .quote-icon-right {
  color: var(--main-active-border);
  font-size: 26px;
}

.section-testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.section-testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.section-testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 15px 15px 15px;
  padding: 20px;
  background: #f3f9fd;
  position: relative;
  margin-bottom: 35px;
  border-radius: 6px;
  line-height: 1.5;
}

.section-testimonials .testimonial-item p::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid #f3f9fd;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
}

/*--------------------------------------------------------------
# single-info box
--------------------------------------------------------------*/

.single-info {
  margin-top: 30px;
  position: relative;
  padding: 40px;
  padding-left: 100px;
  border-radius: 8px;
  overflow: hidden;
  color: #888;

  -webkit-box-shadow: 0 1px 20px 0 rgb(32 32 32 / 11%);
  box-shadow: 0 1px 20px 0 rgb(32 32 32 / 11%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.single-info svg.svg-inline--fa {
  font-size: 30px;
  color: #723fe4;
  position: absolute;
  left: 40px;
  top: 40px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* added for icons */
.single-info i {
  font-size: 30px;
  color: #723fe4;
  position: absolute;
  left: 40px;
  top: 40px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.single-info h3 {
  font-size: 16px;
  font-weight: 600;
  color: #081828;
  margin-bottom: 15px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.single-info ul li a {
  color: #888;
  font-weight: 500;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.section-cta {
  background: var(--main-active-border);
  background-size: cover;
  padding: 60px 0;
}

.section-cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.section-cta p {
  color: #fff;
}

.section-cta .cta-btn {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.section-cta .cta-btn:hover {
  background: #fff;
  color: #2487ce;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.section-pricing .box {
  padding: 40px 20px;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  color: var(--main-active-border);
  box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
}

.section-pricing .box h3 {
  font-weight: 400;
  padding: 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #124265;
}

.section-pricing .box h4 {
  font-size: 42px;
  color: var(--main-active-border);
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
}

.section-pricing .box h4 sup {
  font-size: 26px;
  top: -15px;
  left: -3px;
}

.section-pricing .box h4 span {
  color: #bababa;
  font-size: 20px;
  font-weight: 300;
}

.section-pricing .box ul {
  padding: 0;
  list-style: none;
  color: #124265;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.section-pricing .box ul li {
  padding-bottom: 16px;
  font-weight: 600;
}

.section-pricing .box ul .disabled {
  color: #ccc !important;
  text-decoration: line-through;
}

.section-pricing .box .btn-wrap {
  padding: 15px;
  text-align: center;
}

.section-pricing .box .btn-subscribe {
  display: inline-block;
  padding: 10px 40px;
  border-radius: 4px;
  color: var(--main-active-border);
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  transition: 0.3s;
  border: 2px solid var(--main-active-border);
}

.section-pricing .box .btn-subscribe:hover {
  background: var(--main-active-border);
  color: #fff;
}

.section-pricing .featured {
  background: var(--main-active-border);
}

.section-pricing .featured h3,
.section-pricing .featured h4,
.section-pricing .featured h4 span,
.section-pricing .featured ul,
.section-pricing .featured ul .disabled {
  color: #fff;
}

.section-pricing .featured .btn-wrap {
  padding: 15px;
  text-align: center;
}

.section-pricing .featured .btn-subscribe {
  color: #fff;
  border: 2px solid #fff;
}

.section-pricing .featured .btn-subscribe:hover {
  background: #fff;
  color: var(--main-active-border);
}

/*--------------------------------------------------------------
# counter
--------------------------------------------------------------*/

.counter {
  padding: 3rem;
  border: 1px solid var(--main-nav-footer);
  text-align: center;
  vertical-align: middle;
  box-shadow: 0 0 29px 0 rgb(18 66 101 / 8%);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
}

.counter span.number {
  font-size: 48px;
  line-height: 48px;
  display: block;
  font-weight: 700;
  color: #124265;
  margin-left: 80px;
  margin: auto;
}

.counter p {
  font-size: 20px;
  font-weight: 400;
  padding: 8px 0 0 0;
  letter-spacing: 2px;
}

/*--------------------------------------------------------------
# breadcrumb
--------------------------------------------------------------*/
.breadcrumb {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@media (max-width: 992px) {
  .breadcrumb {
    margin-top: 58px;
  }
}

.breadcrumb h2 {
  font-size: 24px;
  font-weight: 300;
}

.breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumb ol li + li {
  padding-left: 10px;
}

.breadcrumb li {
  font-size: 35px;
  font-weight: 600;
  color: white;
}

.breadcrumb a {
  font-size: 35px;
  font-weight: 600;
  color: var(--main-active-border);
}

.breadcrumb ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: white;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumb .d-flex {
    display: block !important;
  }
  .breadcrumb ol {
    display: block;
  }
  .breadcrumb ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# About Video
--------------------------------------------------------------*/
.about-video .content {
  font-size: 15px;
}

.about-video .content h3 {
  font-weight: 700;
  font-size: 24px;
  color: #124265;
}

.about-video .content ul {
  list-style: none;
  padding: 0;
}

.about-video .content ul li {
  padding-bottom: 10px;
  padding-left: 28px;
  position: relative;
}

.about-video .content ul i {
  font-size: 24px;
  color: var(--main-active-border);
  position: absolute;
  left: 0;
  top: -2px;
}

.about-video .content p:last-child {
  margin-bottom: 0;
}

.about-video .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(
    var(--main-active-border) 50%,
    rgba(36, 135, 206, 0.4) 52%
  );
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.index-video .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(
    var(--main-active-border) 50%,
    rgba(36, 135, 206, 0.4) 52%
  );
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(75% - 47px);
  top: calc(25% - 47px);
  overflow: hidden;
  z-index: 9999;
}

.about-video .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about-video .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid var(--main-active-border);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about-video .play-btn:hover::after {
  border-left: 15px solid var(--main-active-border);
  transform: scale(20);
}

.about-video .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.value {
  font-size: 50px;
  font-weight: 600;
  display: inline;
}

.step p {
  font-size: 24px;
  display: inline;
  font-weight: 400;
  color: var(--main-active-border);
}
/* 
.ReactModalPortal > div {
  z-index: 9999;
} */

.login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000a4;
  z-index: 9999;
}

.text-white {
  font-size: 1.5rem !important;
  margin-bottom: 0.5rem !important;
}

/* coin image slider styles */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.coin-image-slider-base {
  width: 500px;
  /* border-top: 5px solid gray; */
  margin: auto;
  text-align: center;
  position: relative;
  padding: 0 0 30px;
}

.coin-image-slider {
  width: 30px;
  height: 20px;
  /* background: black; */
  margin: auto;
  border-radius: 0 0 50% 50%;
}

.coin-thread {
  display: inline-block;
  width: 2px;
  height: 650px;
  /* background: black; */
  border-radius: 5px;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(240deg);
  animation: moveIt 9s ease-in-out infinite;
}

.coin-thread1 {
  display: inline-block;
  width: 2px;
  height: 650px;
  /* background: black; */
  border-radius: 5px;
  position: relative;
  transform-origin: 50% 0;
  transform: rotate(120deg);
  animation: moveIt1 9s ease-in-out infinite;
}

.coin-thread2 {
  display: inline-block;
  width: 2px;
  height: 650px;
  /* background: black; */
  border-radius: 5px;
  position: relative;
  transform-origin: 50% 0;
  transform: rotate(240deg);
  animation: moveIt2 9s ease-in-out infinite;
}

.coin-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  left: -9px;
}
.coin-image1 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  left: -9px;
}

.coin-image2 {
  width: 200px;
  height: 200px;

  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  left: -9px;
}

@keyframes moveIt {
  0% {
    transform: rotate(0deg);
  }

  33% {
    transform: rotate(120deg);
  }

  66% {
    transform: rotate(240deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveIt1 {
  0% {
    transform: rotate(120deg);
  }

  33% {
    transform: rotate(240deg);
  }

  66% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(480deg);
  }
}

@keyframes moveIt2 {
  0% {
    transform: rotate(240deg);
  }

  33% {
    transform: rotate(360deg);
  }

  66% {
    transform: rotate(480deg);
  }

  100% {
    transform: rotate(600deg);
  }
}

@keyframes moveShade {
  0% {
    transform: translateX(-220px) scale(1.4, 0.5);
    filter: blur(20px);
  }
  50% {
    filter: blur(3px);
  }
  100% {
    transform: translateX(220px) scale(1.4, 0.5);
    filter: blur(20px);
  }
}

ul > li > p,
p {
  line-height: 1.6 !important;
}
.content > p:nth-child(1),
.content > p:nth-child(2) {
  margin-bottom: 1rem;
  line-height: 1.6;
}
